import { useHits, UseHitsProps } from "react-instantsearch-hooks-web";
import { Box, Stack } from "@mui/material";
import { SearchHit, SearchResult } from "./SearchResult";

export function SearchHits(props: UseHitsProps<SearchHit>) {
  const { hits } = useHits<SearchHit>(props);

  return (
    <Box overflow="auto" sx={{ pr: 1 }}>
      <Stack gap={2} sx={{ my: 1 }}>
        {hits.map((hit) => {
          return <SearchResult hit={hit} />;
        })}
      </Stack>
    </Box>
  );
}
