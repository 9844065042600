import {Box, InputAdornment, TextField} from "@mui/material";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {useSearchBox, UseSearchBoxProps} from "react-instantsearch-hooks-web";


export function SearchBar(props: UseSearchBoxProps) {

  const {query, refine} = useSearchBox(props)

  return <Box py={4}>
    <TextField fullWidth placeholder={"Suchen"} value={query} onChange={(e) => refine(e.target.value)} InputProps={{
      endAdornment: (
        <InputAdornment position={"end"}>
          <MagnifyingGlassIcon height="1.7em"/>
        </InputAdornment>
      ),
    }}/>
  </Box>;
}

