import { Box, Container } from "@mui/material";
import { InstantSearch } from "react-instantsearch-hooks-web";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { SearchBar } from "../components/search/SearchBar";
import { SearchHits } from "../components/search/SearchHits";

const searchClient = instantMeiliSearch("https://search.teamdex.io/", "asdf");

export default function Search() {
  return (
    <Container maxWidth="lg">
      <Box
        px={1}
        height="100%"
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <InstantSearch indexName="BEA" searchClient={searchClient}>
          <SearchBar />
          <SearchHits />
        </InstantSearch>
      </Box>
    </Container>
  );
}
