import { useHandleSignInCallback } from "@logto/react";
import { useNavigate } from "react-router-dom";

export const LoginCallback = () => {
  const navigate = useNavigate();

  const { isLoading } = useHandleSignInCallback(() => {
    navigate("/", { replace: true });
  });

  return isLoading ? <div>Redirecting...</div> : <div></div>;
};
