import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import UserProfile from "./pages/UserProfile";
import { LoginCallback } from "./auth/LoginCallback";
import Search from "./pages/Search";
import AuthGuard from "./auth/AuthGuard";
import DrawerLayout from "./layouts/DrawerLayout";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthGuard>
              <DrawerLayout />
            </AuthGuard>
          }
        >
          <Route index element={<Navigate to={"search"} />} />
          <Route path="search" element={<Search />} />
          <Route path="profile" element={<UserProfile />} />
        </Route>
        <Route path="callback" element={<LoginCallback />} />
        <Route path="*" element={<div>Not found</div>} />
      </Routes>
    </BrowserRouter>
  );
}
