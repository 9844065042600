import Box from "@mui/material/Box";
import MiniDrawer from "../components/Drawer";
import { Outlet } from "react-router-dom";
import React from "react";

export default function DrawerLayout() {
  return (
    <Box flex={"auto"} display="flex" height={"100%"}>
      <MiniDrawer />
      <Outlet />
    </Box>
  );
}
