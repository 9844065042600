import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import { useLogto } from "@logto/react";
import Box from "@mui/material/Box";

export default function UserProfile() {
  const { signOut } = useLogto();

  return (
    <Container sx={{ py: 3 }}>
      <Card elevation={2}>
        <CardContent>
          <Box justifyContent="space-between" display="flex" mt={1}>
            <Typography variant="h4">Mein Profil</Typography>
            <Button
              size="large"
              onClick={() =>
                signOut(`${window.location.protocol}//${window.location.host}/`)
              }
            >
              Abmelden
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
