import { useEffect } from "react";
import { useLogto } from "@logto/react";

export default function AuthGuard({ children }: { children: JSX.Element }) {
  const { isAuthenticated, signIn } = useLogto();

  useEffect(() => {
    if (!isAuthenticated) {
      void signIn(
        `${window.location.protocol}//${window.location.host}/callback`
      );
    }
  }, [isAuthenticated, signIn]);

  return isAuthenticated ? children : null;
}
