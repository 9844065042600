import * as React from "react";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import cssStyled from "@emotion/styled";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  MagnifyingGlassIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const GradientText = cssStyled.span`
  background: linear-gradient(90deg, hsla(159, 82%, 55%, 1) 0%, hsla(206, 98%, 48%, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 1.41em;
`;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function DrawerItem({
  open,
  text,
  icon,
  href,
}: {
  open: boolean;
  text: string;
  icon: React.ReactNode;
  href: string;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isActive = href === pathname;

  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        selected={isActive}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
        onClick={() => navigate(href)}
      >
        <ListItemIcon style={{ justifyContent: "center" }}>{icon}</ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}

const drawerItems = [
  {
    href: "/search",
    text: "Suche",
    icon: <MagnifyingGlassIcon height="2em" />,
  },
  {
    href: "/contacts",
    text: "Kontakte",
    icon: <UsersIcon height="2em" />,
  },
];

export default function MiniDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{ d: "flex", flexDirection: "column" }}
    >
      <DrawerHeader>
        <GradientText>{open ? "TeamDex" : "TD"}</GradientText>
        <IconButton onClick={toggleDrawer}>
          {open ? (
            <ChevronDoubleLeftIcon height="1em" />
          ) : (
            <ChevronDoubleRightIcon height="1em" />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {drawerItems.map((item) => {
          return (
            <DrawerItem
              key={item.href}
              open={open}
              href={item.href}
              text={item.text}
              icon={item.icon}
            />
          );
        })}
      </List>
      <div style={{ flexGrow: 1 }}></div>
      <List>
        <DrawerItem
          open={open}
          href="/profile"
          text="Mein Profil"
          icon={<UserIcon height="2em" />}
        />
      </List>
    </Drawer>
  );
}
