import React from "react";
import "./App.css";
import { CssBaseline } from "@mui/material";
import { LogtoConfig, LogtoProvider } from "@logto/react";
//@ts-ignore
import * as Sentry from "@sentry/react";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import Router from "./Router";

if (process.env.NODE_ENV !== "development") {
  //Track users with logrocket
  LogRocket.init("selectcode/teamdex");
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope: any) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });

  LogRocket.getSessionURL((sessionURL) => {
    //@ts-ignore
    pendo.track("Session Replay Created", {
      sessionURL,
    });
  });
}

const config: LogtoConfig = {
  endpoint: "https://auth.teamdex.io",
  appId: "uHstPBKzrbVpu8105HkWI",
};

// TODO: with authentication
// // This is an example script - don't forget to change it!
// LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
//     name: 'James Morrison',
//     email: 'jamesmorrison@example.com',
//
//     // Add your own custom user variables here, ie:
//     subscriptionType: 'pro'
// });

if (process.env.NODE_ENV !== "development") {
  //@ts-ignore
  pendo.initialize();
}

// This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
// This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
// Call this function in your authentication promise handler or callback when your visitor and account id values are available
// Please use Strings, Numbers, or Bools for value types.
// pendo.initialize({
//     visitor: {
//         id:              'VISITOR-UNIQUE-ID'   // Required if user is logged in, default creates anonymous ID
//         // email:        // Recommended if using Pendo Feedback, or NPS Email
//         // full_name:    // Recommended if using Pendo Feedback
//         // role:         // Optional
//
//         // You can add any additional visitor level key-values here,
//         // as long as it's not one of the above reserved names.
//     },
//
//     account: {
//         id:           'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
//         // name:         // Optional
//         // is_paying:    // Recommended if using Pendo Feedback
//         // monthly_value:// Recommended if using Pendo Feedback
//         // planLevel:    // Optional
//         // planPrice:    // Optional
//         // creationDate: // Optional
//
//         // You can add any additional account level key-values here,
//         // as long as it's not one of the above reserved names.
//     }
// });

function App() {
  return (
    <LogtoProvider config={config}>
      <CssBaseline />
      <Router />
    </LogtoProvider>
  );
}

export default App;
