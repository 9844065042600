import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import type { BaseHit, Hit } from "instantsearch.js";
import { Highlight } from "react-instantsearch-hooks-web";

export interface SearchHit extends Hit<BaseHit> {
  id: number;

  jahrgang: number;

  image: string;

  name: string;

  jobTitle: string;
}

export function SearchResult({ hit }: { hit: SearchHit }) {
  return (
    <Card elevation={2}>
      <Box display="flex">
        <CardMedia
          image={hit.image}
          component={"img"}
          alt={hit.name}
          sx={{ width: "175px" }}
        />
        <Box>
          <CardContent>
            <Typography variant="h6">
              <Highlight hit={hit} attribute={"name"} />
            </Typography>
            <Typography>
              <b>Jahrgang: </b>
              <Highlight hit={hit} attribute={"jahrgang"} />
            </Typography>
            <Typography>
              <b>Job: </b>
              <Highlight hit={hit} attribute={"jobTitle"} />
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Connect</Button>
          </CardActions>
        </Box>
      </Box>
    </Card>
  );
}
